import { FC, ReactNode } from 'react';
import {
  Card,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const PageBanner: FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container disableGutters={mobileDevice}>
      <Card elevation={0}>
        <CardHeader
          color="#000"
          title={
            <>
              <img src="/images/whiff-art.png" alt="" loading="lazy" width={mobileDevice ? 150 : 200} />
              <br />
              <img src="/images/whiff-logo.png" alt="" loading="lazy" width={mobileDevice ? 200 : 300} />
            </>
          }
          titleTypographyProps={{ margin: '64px 80px 16px', textAlign: 'center' }}
          subheader="Uplifting Artisanal Fragrances"
          subheaderTypographyProps={{
            variant: mobileDevice ? 'subtitle2' : 'subtitle1',
            margin: '0 80px',
            textAlign: 'center',
          }}
        />
        <CardMedia component="img" image="/images/Header-Range-Shot.jpg" alt="" />
      </Card>
    </Container>
  );
};

interface PageSectionProps {
  id: string;
  textContent: ReactNode;
  imageContent?: ReactNode;
  altBackground?: boolean;
}

const PageSection: FC<PageSectionProps> = (props) => {
  const { altBackground, id, textContent, imageContent } = props;
  const theme = useTheme();

  return (
    <Container id={id} sx={{ minHeight: 300, background: altBackground ? theme.palette.grey[200] : 'transparent' }}>
      <Grid container spacing={4} pt={6} pb={6}>
        <Grid item xs={12} md={imageContent ? 6 : 12} lg={imageContent ? 7 : 12}>
          {textContent}
        </Grid>
        {imageContent && (
          <Grid item xs={12} md={6} lg={5}>
            {imageContent}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

interface PageSectionHeadingProps {
  children?: ReactNode;
}

const PageSectionHeading: FC<PageSectionHeadingProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Typography pb={2} variant={mobileDevice ? 'h4' : 'h3'}>
      {children}
    </Typography>
  );
};

interface PageSectionContentProps {
  children?: ReactNode;
}

const PageSectionContent: FC<PageSectionContentProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Typography pb={2} variant={mobileDevice ? 'body2' : 'body1'}>
      {children}
    </Typography>
  );
};

const HomePage: FC = () => (
  <>
    <PageBanner />
    <PageSection
      id="section-story"
      altBackground
      textContent={
        <>
          <PageSectionHeading>Our Story</PageSectionHeading>
          <PageSectionContent>
            Whiff was a creative outlet to mend a broken heart. Our founder went on a trip to Grasse France and immersed
            herself in the art of perfumery. She realized that fragrances had a way of uplifting one’s spirit because it
            had the unique power to transport the user to happier times and to evoke fond memories.
          </PageSectionContent>
          <PageSectionContent>
            Starting off as an online brand exclusively sold on Social Media, Whiff quickly gained a cult following
            because of its soft, subtle and long lasting fragrances that were frequently compared to global brands.
          </PageSectionContent>
        </>
      }
      imageContent={
        <ImageList variant="quilted" cols={1} rowHeight={450}>
          <ImageListItem>
            <img src="/images/Our-Story-Nectarine-Option-1.jpg" alt="" loading="lazy" />
          </ImageListItem>
        </ImageList>
      }
    />
    <PageSection
      id="section-methods"
      textContent={
        <>
          <PageSectionHeading>Our Methods</PageSectionHeading>
          <PageSectionContent>
            Every Whiff fragrance is made with the passion of an artisan. We use the finest ingredients that are safe
            and used by international fragrance houses.
          </PageSectionContent>
          <PageSectionContent>
            The ingredients are blended in precise quantities using both time-honored techniques and modern
            manufacturing practices. The final product is aged to perfection before being bottled and packed in our
            distinctive packaging.
          </PageSectionContent>
          <PageSectionContent>
            Our collaboration with RJ Lopena, a perfumer who attended the Grasse Institute of Perfumery in France, not
            only allowed us to create our signature fragrances, assures you of world class Filipino perfumery in every
            bottle.
          </PageSectionContent>
        </>
      }
      imageContent={
        <ImageList cols={2} variant="woven" gap={8}>
          <ImageListItem>
            <img src="/images/Our-Method-Artisan.jpg" alt="" loading="lazy" />
          </ImageListItem>
          <ImageListItem rows={2}>
            <img src="/images/RJ-Lopena.jpg" alt="" loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src="/images/Petals.jpg" alt="" loading="lazy" />
          </ImageListItem>
        </ImageList>
      }
    />
    <PageSection
      id="section-classics"
      altBackground
      textContent={
        <>
          <PageSectionHeading>Our Classics</PageSectionHeading>
          <PageSectionContent>These fragrances have stood the test of time:</PageSectionContent>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Nectarine Passion
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                Nectarines picked at the peak of freshness in a light cologne-like Eau de Parfum.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Spring Floral Drops
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                Refreshingly fruity, wonderfully floral, totally feminine.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Autumn Dew
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                Crisp pears, freesia, patchouli and rose make this a favorite amongst both our male and female clients.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Pacific Rain
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                A wonderfully outdoorsy fragrance combining tart limes and woody cedar notes. Equally loved by men and
                women.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                White Drops
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                A unique floral fragrance with herbaceous undertones. A must have for the woman who stands out.
              </Typography>
            </Grid>
          </Grid>
        </>
      }
      imageContent={
        <ImageList variant="quilted" cols={1} rowHeight={500}>
          <ImageListItem>
            <img src="/images/Our-Classics.jpg" alt="" loading="lazy" />
          </ImageListItem>
        </ImageList>
      }
    />
    <PageSection
      id="section-signature"
      textContent={
        <>
          <PageSectionHeading>Our Signature Scents</PageSectionHeading>
          <PageSectionContent>
            Fragrances we originated in collaboration with RJ Lopena, world class Filipino perfumes we can all be proud
            of:
          </PageSectionContent>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Lily and White Musk
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                Our original signature fragrance was created by GIP Trained RJ Lopena. An elegant, sophisticated
                fragrance with a Lily of the Valley heart note.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Cherry Blossom
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                Our Cherry Blossom bursts with the freshness and optimism of spring. Its unforgettable fragrance
                transports you to Tokyo at the heart of Hanami. It's sure to get you #TickledPink.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Mango Verde
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                A delightful, refreshing homage to the queen of the Philippine fruits.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Pine & Lime Pleasure
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                The freshness of pine and sweet-sour limes deliver a fresh out of the shower fragrance that's great for
                men and women.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Gardenia Bliss
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                When romance is the order of the day, indulge in the scent of gardenias in full bloom with subtle
                undertones of pomelo.
              </Typography>
            </Grid>
          </Grid>
        </>
      }
      imageContent={
        <ImageList variant="quilted" cols={2} gap={8} rowHeight={400}>
          <ImageListItem cols={2}>
            <img src="/images/Our-Signature-01.jpg" alt="" loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src="/images/Our-Signatures-Lily-Option-1.jpg" alt="" loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src="/images/Our-Signature-Cherry-Blossom.jpg" alt="" loading="lazy" />
          </ImageListItem>
        </ImageList>
      }
    />
    <PageSection
      id="section-room"
      altBackground
      textContent={
        <>
          <PageSectionHeading>Our Room Sprays</PageSectionHeading>
          <PageSectionContent>
            Perfect your room’s ambiance with our artisanal room sprays, they’re safe to use on fabrics too:
          </PageSectionContent>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Flora Bianca
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                Dim the lights, light some candles and create a romantic ambiance with this gentle and lasting
                fragrance.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Green Tea
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                A relaxing fragrance that puts you in a Zen-like state of mind. It's perfect when you need to create a
                restful ambiance after a long, tiring day.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" fontWeight="600">
                Mango Verde
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" pb={1}>
                It’s so good, we had to offer our delightful, refreshing homage to the queen of the Philippine fruits in
                a room spray.
              </Typography>
            </Grid>
          </Grid>
        </>
      }
      imageContent={
        <ImageList variant="quilted" cols={1}>
          <ImageListItem>
            <img src="/images/Our-Room-Sprays.jpg" alt="" loading="lazy" />
          </ImageListItem>
        </ImageList>
      }
    />
    <PageSection
      id="section-buy"
      textContent={
        <>
          <PageSectionHeading>Where to Buy</PageSectionHeading>
          <PageSectionContent>
            Drop us a line through our social media accounts:
            <br />
            <Link href="https://www.instagram.com/whiffperfumesph" color="#000">
              www.instagram.com/whiffperfumesph
            </Link>
            <br />
            <Link href="http://www.facebook.com/whiffperfumesph" color="#000">
              www.facebook.com/whiffperfumesph
            </Link>
          </PageSectionContent>
          <PageSectionContent>Or visit us at our Physical Store at:</PageSectionContent>
          <PageSectionContent>
            <strong>Chimes Boutique:</strong>
            <br />
            Governor Sales Street, Poblacion District, Davao City, 8000 Davao del Sur
            <br />
          </PageSectionContent>
          <PageSectionContent>
            And now in&nbsp;
            <a href="https://s.lazada.com.ph/s.UzpdN">
              <strong>Lazada</strong>
            </a>
            .
            <br />
            <a href="https://s.lazada.com.ph/s.UzpdN">
              <img src="/images/lazada-logo.svg" alt="Lazada" width="240px" title="Shop at Lazada" />
            </a>
          </PageSectionContent>
        </>
      }
    />
    <PageSection
      id="section-contact"
      altBackground
      textContent={
        <>
          <PageSectionHeading>How to Contact Us</PageSectionHeading>
          <PageSectionContent>
            Drop us a line through our social media accounts:
            <br />
            <Link href="https://www.instagram.com/whiffperfumesph" color="#000">
              www.instagram.com/whiffperfumesph
            </Link>
            <br />
            <Link href="http://www.facebook.com/whiffperfumesph" color="#000">
              www.facebook.com/whiffperfumesph
            </Link>
          </PageSectionContent>
          <PageSectionContent>
            Or send us an email at:
            <br />
            <Link href="mailto:whiffcustomerservice@gmail.com" color="#000">
              whiffcustomerservice@gmail.com
            </Link>
          </PageSectionContent>
        </>
      }
    />
  </>
);

export default HomePage;
