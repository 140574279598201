import { FC, ReactElement } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#dbbe5d',
      light: '#F4ECCE',
      dark: '#C49930',
    },
  },
  typography: {
    fontFamily: '"Martel Sans", "Avenir Next", "Avenir", "Helvetica", "Arial", "sans-serif"',
    h1: {
      fontFamily: '"Cormorant Garamond", "Garamond", "Times", "Times New Roman", serif',
    },
    h2: {
      fontFamily: '"Cormorant Garamond", "Garamond", "Times", "Times New Roman", serif',
    },
    h3: {
      fontFamily: '"Cormorant Garamond", "Garamond", "Times", "Times New Roman", serif',
    },
    h4: {
      fontFamily: '"Cormorant Garamond", "Garamond", "Times", "Times New Roman", serif',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.9)',
          color: '#dbbe5d',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '--Button-iconOffsetStep': 0,
          ...(ownerState.variant === 'contained' && {
            '&.Mui-focusVisible, &:focus-visible': {
              outlineOffset: '-3px',
              outlineColor: '#fff',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            '&.Mui-focusVisible, &:focus-visible': {
              outlineOffset: '-3px',
            },
          }),
          ...(ownerState.size === 'medium' && {
            '--Icon-fontSize': '20px',
            fontSize: '14px',
            fontWeight: 600,
            minHeight: '32px',
            borderRadius: '2px',
            paddingLeft: 20,
            paddingRight: 20,
          }),
        }),
      },
    },
  },
});

interface StyleProviderProps {
  children: ReactElement;
}

const StyleProvider: FC<StyleProviderProps> = ({ children }) => (
  <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
);

export default StyleProvider;
