import StyleProvider from './components/StyleProvider';

import AppHeader from './components/AppHeader';
import HomePage from './pages/HomePage';

const AppScaffolding = () => (
  <StyleProvider>
    <>
      <AppHeader />
      <HomePage />
    </>
  </StyleProvider>
);

export default AppScaffolding;
