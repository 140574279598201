import { FC, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MenuIcon from '@mui/icons-material/Menu';

const AppHeader: FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollToSection = (id: string) =>
    document.getElementById(id)?.scrollIntoView({ block: 'start', inline: 'nearest' });

  return (
    <>
      <AppBar position="fixed">
        <Toolbar sx={{ display: 'flex' }}>
          <Grid container p={0} spacing={0}>
            <Grid item xs={4} md={3} lg={2} display="flex" alignItems="center" pr={2}>
              <Hidden mdDown>
                <img src="/images/whiff-logo_x32.png" alt="Whiff" />
              </Hidden>
              <Hidden mdUp>
                <IconButton color="primary" onClick={() => setMenuOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={4} md={6} lg={8} alignItems="center" display="flex" justifyContent="center">
              <Hidden mdDown>
                <Stack direction="row">
                  <Button variant="text" onClick={() => scrollToSection('section-story')}>
                    Our Story
                  </Button>
                  <Button variant="text" onClick={() => scrollToSection('section-methods')}>
                    Our Methods
                  </Button>
                  <Button variant="text" onClick={() => scrollToSection('section-classics')}>
                    Our Classics
                  </Button>
                  <Button variant="text" onClick={() => scrollToSection('section-signature')}>
                    Our Signature Scents
                  </Button>
                  <Button variant="text" onClick={() => scrollToSection('section-room')}>
                    Our Room Sprays
                  </Button>
                  <Button variant="text" onClick={() => scrollToSection('section-buy')}>
                    Where To Buy
                  </Button>
                  <Button variant="text" onClick={() => scrollToSection('section-contact')}>
                    Contact Us
                  </Button>
                </Stack>
              </Hidden>
              <Hidden mdUp>
                <img src="/images/whiff-logo_x32.png" alt="Whiff" />
              </Hidden>
            </Grid>
            <Grid item xs={4} md={3} lg={2} alignItems="center" display="flex" justifyContent="flex-end" pl={2}>
              <IconButton size="medium" component="a" color="primary" href="https://www.facebook.com/whiffperfumesph/">
                <FacebookIcon />
              </IconButton>
              <IconButton size="medium" color="primary" component="a" href="https://www.instagram.com/whiffperfumesph/">
                <InstagramIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={menuOpen && mobileDevice} onClose={() => setMenuOpen(false)}>
        <Box
          sx={{ width: 300 }}
          role="presentation"
          onClick={() => setMenuOpen(false)}
          onKeyDown={() => setMenuOpen(false)}
        >
          <List>
            <ListItem>
              <ListItemButton color="primary" onClick={() => scrollToSection('section-story')}>
                <ListItemText primary="Our Story" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton color="primary" onClick={() => scrollToSection('section-methods')}>
                <ListItemText primary="Our Methods" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton color="primary" onClick={() => scrollToSection('section-classics')}>
                <ListItemText primary="Our Classics" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton color="primary" onClick={() => scrollToSection('section-signature')}>
                <ListItemText primary="Our Signature Scents" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton color="primary" onClick={() => scrollToSection('section-room')}>
                <ListItemText primary="Our Room Sprays" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton color="primary" onClick={() => scrollToSection('section-buy')}>
                <ListItemText primary="Where To Buy" />
              </ListItemButton>
            </ListItem>
            <ListItem color="primary" onClick={() => scrollToSection('section-contact')}>
              <ListItemButton>
                <ListItemText primary="Contact Us" color="primary" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default AppHeader;
